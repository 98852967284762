<template>
    <button class="btn btn-lg border-dark border-1" :class="classNames" :disabled="disabled" @click="handleClick">
        <template v-if="icon">
            <span><font-awesome-icon v-if="!isIconAfter" :class="iconClass" :icon="icon" class="me-2" /></span>
            <span>{{ $T.t(text) }}</span>
            <span><font-awesome-icon v-if="isIconAfter" :class="iconClass" :icon="icon" class="ms-2" /></span>
        </template>
        <template v-else>
            <span>{{ $T.t(text) }}</span>
        </template>
    </button>
</template>


<script lang="ts" setup>
import { StandardEmitEvent } from '@/enums/standardEmitEvent.enum';
import useI18n from '@/composables/useI18n';

const props = defineProps({
    text: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    classNames: {
        type: String,
        default: ''
    },
    iconClass: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    isIconAfter: {
        type: Boolean,
        default: true,
    },
    href: {
        type: String,
        default: null
    },
    url: {
        type: String,
        default: null
    }
})

const { $T } = useI18n();

const emit = defineEmits([StandardEmitEvent.CLICK]);

function handleClick(event: Event): boolean | void {
    const url = props.url ? $T.t(props.url) : props.href;

    if (url) {
        window.open(url, "_blank"); // Open in a new tab
        return;
    }

    emit(StandardEmitEvent.CLICK, event);
}

</script>

<style scoped lang="scss">
@import '@/styles/components/base/_baseFormButton';
</style>