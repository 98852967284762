<template>
    <div class="p-2 row">
        <div class="col-auto icon-box">
            <font-awesome-icon :icon="['fas', 'fa-circle']" size="2xs" />
        </div>
        <div class="col">
            <div class="row m-0">
                <div class="col-6 p-0">
                    <BaseText text="Stop" classNames="bold" :textFromTextkey="false"></BaseText>
                    <BaseText :text=stopName :textFromTextkey="false"></BaseText>
                </div>
                <div class="col">
                    <BaseText :text="departure ? departureText : arrivalText" classNames="bold">
                    </BaseText>
                    <BaseText :text=time :textFromTextkey="false"></BaseText>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup lang ='ts'>
import BaseText from '@/components/base/BaseText.vue';

defineProps({
    stopName: {
        type: String,
        required: true,
        default: ''
    },
    time: {
        type: String,
        required: true
    },
    departure: {
        type: Boolean,
        default: true
    }
})
const departureText = "InstantApprovalPage.ResultScreen.alternativeRoute.leg.departure";
const arrivalText = "InstantApprovalPage.ResultScreen.alternativeRoute.leg.arrival";

</script>
  
<style scoped lang ="scss">
@import '@/styles/components/instantApproval/result/rejected/_tripItem';
</style>