import { Route } from '@/models/Route.model';
import useI18n from '@/composables/useI18n';
import { TransportType } from '@/enums/transportType.enum';
import type OptionModel from '@/models/option.model';
import { useTimeTableStore } from '@/stores/timeTablesStore';
import { toHourAndMinutes } from '@/utils/datetime-util'

export default function useOptions(id: string, route: Route) {
    const { $T } = useI18n();
    const timeTableStore = useTimeTableStore();


    const dependencies: { [key in keyof Route]?: keyof Route } = {
        'StoppingplaceFrom': 'RouteLineDetails',
        'Direction': 'StoppingplaceFrom',
        'DepartureTime': 'Direction',
        'StoppingplaceTo': 'DepartureTime'
    };

    const fetchData = (field: keyof Route): OptionModel[] => {
        switch (field) {
            case 'TransportType':
                return Object.values(TransportType).map(getLocalizedTransportType);
            case 'RouteLineDetails':
                return timeTableStore.getRoutes(id).map(x => ({ value: x, displayValue: x.lineName || x.lineNumber || '' }));
            case 'StoppingplaceFrom':
                return timeTableStore.getStopsFrom(id).map(x => ({ value: x, displayValue: x.stopName || "" }));
            case 'Direction':
                return timeTableStore.getDirections(id).map(x => ({ value: x, displayValue: x.toString() || "" }));
            case 'DepartureTime':
                return timeTableStore.getTours(id, route.Direction ?? "").map(x => ({ value: x, displayValue: toHourAndMinutes(x.departureTime?.toString() ?? "") || "" }));
            case 'StoppingplaceTo':
                return timeTableStore.getStopsTo(id).map(x => ({ value: x, displayValue: x.stopName || "" }));
            default:
                return [];
        }
    };
    const getOptions = (field: keyof Route, dependency: boolean): OptionModel[] => {
        const defaultOption = {
            value: undefined,
            displayValue: $T.t('InstantApprovalPage.select.' + field)
        };

        const fetchedOptions = fetchData(field);
        const hasDependency = dependencies[field] && dependency;

        if (fetchedOptions.length === 0 && hasDependency) {
            defaultOption.displayValue = $T.t('InstantApprovalPage.select.no.options.' + field);
        }

        if (['StoppingplaceFrom', 'Direction', 'StoppingplaceTo', 'RouteLineDetails'].includes(field)) {
            fetchedOptions.sort((a, b) => {
                const matchA = a.displayValue.match(/^\d+/);
                const matchB = b.displayValue.match(/^\d+/);

                const numA = matchA ? parseInt(matchA[0], 10) : 0;
                const numB = matchB ? parseInt(matchB[0], 10) : 0;

                // If the numerical parts are equal, fallback to locale-specific comparison
                if (numA === numB) return a.displayValue.localeCompare(b.displayValue, 'da-DK');

                return numA - numB;
            });
        }

        return [defaultOption, ...fetchedOptions];
    };

    const getLocalizedTransportType = (transportType: TransportType): OptionModel => {
        return {
            value: transportType,
            displayValue: $T.t(`TransportType.${transportType}`)
        };
    };

    return { getOptions };
};
