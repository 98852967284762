<template>
    <div class="card postion-relative">
        <font-awesome-icon v-if=moreThanOneRoute class="fa-2x position-absolute top-0 end-0 pt-2 pe-2 z-1"
            :icon="['fass', 'xmark']" @click="deleteRoute" />
        <div class="card-title">
            <BaseHeading
                :heading="route.RouteLineDetails ? $T.t('InstantApprovalPage.RouteLineDetails.heading') + route.RouteLineDetails.lineNumber : $T.t('InstantApprovalPage.select.RouteLineDetails')"
                :headingFromTextkey="false" classNames="bold" :level=5 />
        </div>
        <BaseSelect v-model="route.TransportType"
            @update:modelValue="value => { route.TransportType = value; fetchRoutes(value); resetRoutes() }"
            :options="getOptions('TransportType', true)" />
        <BaseSelect :searchable="true" v-model="route.RouteLineDetails"
            :options="getOptions('RouteLineDetails', route.TransportType != null)" :is-disabled="isRouteDisabled"
            @update:modelValue="value => { route.RouteLineDetails = value; getStopsFrom() }" />
    </div>
</template>

<script setup lang="ts">
import useI18n from '@/composables/useI18n';
import { computed } from 'vue';
import BaseSelect from '../../base/BaseSelect.vue';
import BaseHeading from '../../base/BaseHeading.vue';
import { useTimeTableStore } from '@/stores/timeTablesStore';
import { useDetailsStore } from '@/stores/detailsStore';
import { Status } from '@/enums/status.enum';
import useOptions from '@/composables/useOptions';


const store = useDetailsStore();
let timeTableStore = useTimeTableStore();

const { $T } = useI18n();

const props = defineProps({
    id: {
        type: String,
        required: true
    }
});

const route = store.routeById(props.id);
const moreThanOneRoute = computed(() => store.numberOfRoutes() > 1);
const isRouteDisabled = computed(() =>
    !route.TransportType ||
    (timeTableStore.statusState.status === Status.LOADING || timeTableStore.statusState.status === Status.ERROR));

const { getOptions } = useOptions(props.id, route);

function deleteRoute() {
    store.deleteRoute(props.id);
}

const fetchRoutes = (transportType: String) => {
    timeTableStore.fetchRoutes(transportType, props.id);
}

function getStopsFrom() {
    timeTableStore.fetchStops(route, props.id);
}

function resetRoutes() {
    route.RouteLineDetails = null;
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/components/instantApproval/routeCards';
</style>
