<template>
    <div class="container">
        <div v-if="bulletTitle == undefined">
            <BaseText v-for="(text, index) in content" :text="text" :key="index" classNames="white p-1 mb-0"
                :textFromTextkey="false">
            </BaseText>
        </div>
        <div v-else>
            <BaseText :text="bulletTitle" classNames="white p-1 mb-0" :textFromTextkey="false"></BaseText>
            <ul class="pb-0 mb-0">
                <li v-for="(text, index) in content" :key="index">
                    <BaseText :text=text classNames="white p-1 mb-0" :textFromTextkey="false"></BaseText>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import BaseText from '../base/BaseText.vue';
import useI18n from '@/composables/useI18n';

const { getMessagesByPrefix } = useI18n();

const props = defineProps({
    answerFor: {
        type: String,
        required: true
    },

});

const prefix = props.answerFor + ".content";
const content = getMessagesByPrefix(prefix);

const titlePrefix = props.answerFor + ".title";
const bulletTitle = getMessagesByPrefix(titlePrefix)[0] ?? undefined;
</script>

<style lang="scss" scoped>
@import '@/styles/components/home/faqAccordion';
</style>
