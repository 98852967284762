<template>
    <div class="position-relative h-100 w-100">
        <div v-if="error" class="position-absolute w-100 top-0 d-flex justify-content-center">
            <ErrorAlert :message="errorMessage" :retry="retryError"></ErrorAlert>
        </div>
        <div v-else class="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <img alt="Loading icon" class="mb-2 svg-icon bounce" src="@/assets/images/loading_icon.svg" />
            <span>{{ $T.t("Loadingscreen.Controlling") }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import useI18n from '@/composables/useI18n';
import ErrorAlert from '../ErrorAlert.vue';
import { useErrorHandler } from '@/composables/useErrorHandler';

const { error, errorMessage, retryError } = useErrorHandler(false);

const { $T } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/styles/components/instantApproval/_loadingScreen';
</style>
