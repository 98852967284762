<template>
    <div class="form-check d-flex align-items-center">
        <input class="form-check-input" type="checkbox" value=""
            :class="[{ 'form-control-error': v$[vuelidateField].$error }]" v-model="v$[vuelidateField].$model"
            id="flexCheckDefault">
        <slot name="text">
        </slot>
    </div>
    <template v-if="v$[vuelidateField].$dirty">
        <div v-for="error in v$[vuelidateField].$silentErrors" :key="error.$message.toString()" class="error-message">
            {{ error.$message }}
        </div>
    </template>
</template>


<script lang="ts" setup>
import useI18n from '@/composables/useI18n';
import useVuelidate from '@vuelidate/core';
import { computed, ref, watch } from 'vue';

const props = defineProps({
    label: {
        type: String,
        default: ''
    },
    modelValue: {
        type: Boolean,
        default: false
    },
    rules: {
        type: Object,
        default: null
    },
})

const fieldValue = ref(props.modelValue);
const { $T } = useI18n();
const vuelidateField = computed(() => $T.t(props.label));
const v$ = useVuelidate({ [vuelidateField.value]: props.rules }, { [vuelidateField.value]: fieldValue });

const emit = defineEmits(['update:modelValue']);

watch(fieldValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal);
    }
});
</script>

<style lang="scss" scoped>
@import '@/styles/components/base/_baseInputField';
</style>