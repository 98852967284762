<template>
    <div class="card mb-3">
        <div class="card-body m-2">
            <BaseHeading heading="InstantApprovalPage.ResultScreen.info.heading" helpLink="Placeholder" :icon="questionIcon"
                classNames="bold" :level=4>
            </BaseHeading>
            <BaseIconText>
                <template #icon>
                    <CarIcon></CarIcon>
                </template>
                <template #text>
                    <BaseHeading heading="InstantApprovalPage.ResultScreen.info.car.title" classNames="bold" :level=5>
                    </BaseHeading>
                </template>
            </BaseIconText>
            <BaseBulletList :bullets="carBullets" :textFromTextkey=true></BaseBulletList>
            <BaseIconText>
                <template #icon>
                    <TaxaIcon></TaxaIcon>
                </template>
                <template #text>
                    <BaseHeading heading="InstantApprovalPage.ResultScreen.info.taxi.title" classNames="bold" :level=5>
                    </BaseHeading>
                </template>
            </BaseIconText>
            <BaseBulletList :bullets="taxiBullets" :textFromTextkey=true></BaseBulletList>
            <BaseText text="InstantApprovalPage.ResultScreen.info.description"></BaseText>
        </div>
    </div>
</template>

<script setup lang="ts">
import CarIcon from '@/components/icons/CarIcon.vue';
import TaxaIcon from '@/components/icons/TaxaIcon.vue';
import BaseIconText from '@/components/base/BaseIconText.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseBulletList from '@/components/base/BaseBulletList.vue';
import questionIcon from '@/assets/images/question.svg';
import useI18n from '@/composables/useI18n';

const { getMessagesByPrefix } = useI18n();

const prefixCar = "InstantApprovalPage.ResultScreen.approved.Car";
const prefixTaxi = "InstantApprovalPage.ResultScreen.approved.Taxi";

const carBullets = getMessagesByPrefix(prefixCar);
const taxiBullets = getMessagesByPrefix(prefixTaxi);

</script>
