<template>
    <div>
        <div>
            <BaseIconText>
                <template #icon>
                    <TrainIcon v-if="leg.isTrainLeg"></TrainIcon>
                    <BusIcon v-if="!leg.isTrainLeg"></BusIcon>
                </template>
                <template #text>
                    <BaseText :text="leg.name" :textFromTextkey="false" classNames="bold m-0"></BaseText>
                </template>
            </BaseIconText>
        </div>
        <TripItem :stopName="leg.startStop.name" :time="departureTime"></TripItem>
        <TripItem :stopName="leg.endStop.name" :time="arrivalTime" :departure="false"></TripItem>
    </div>
</template>

<script setup lang="ts">
import TrainIcon from '@/components/icons/TrainIcon.vue';
import BusIcon from '@/components/icons/BusIcon.vue';
import TripItem from './TripItem.vue';
import BaseIconText from '@/components/base/BaseIconText.vue';
import BaseText from '@/components/base/BaseText.vue';
import { AlternativeTripLeg } from '@/models/AlternativeTrip.model';
import { toDanishDateTime, toHourAndMinutes } from '@/utils/datetime-util'

const props = defineProps({
    leg: {
        type: Object as () => AlternativeTripLeg,
        required: true,
    },
})

let danishArrivalTime = toDanishDateTime(props.leg.arrivalTime.toString());
let danishdepartureTime = toDanishDateTime(props.leg.departureTime.toString());

let arrivalTime = toHourAndMinutes(danishArrivalTime.toString());
let departureTime = toHourAndMinutes(danishdepartureTime.toString());
</script>
