import { defineStore } from 'pinia'
import axios from 'axios'
import { RouteLineDetails, type Route } from '@/models/Route.model';
import type { Tour } from '@/models/Trip.model';
import { Stop } from '@/models/Route.model';
import { type StatusState, Status } from '@/enums/status.enum';
import { IsEarlier, toHourAndMinutes } from '@/utils/datetime-util';

const baseUrl = import.meta.env.VITE_BACKEND_URL as string + "api/v1/travelguarantee";

const doPost = (body: Object, endpoint: String) => {
  const url = baseUrl + "/" + endpoint;
  return axios.post(url, body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
}

export const useTimeTableStore = defineStore("timeTables", {
  state: () => ({
    timetables: [] as {
      id: string
      routes: RouteLineDetails[],
      selectedRoute: RouteLineDetails | undefined,
      stopsFrom: Stop[] | undefined;
      stopsTo: { stopName: string, stopNumber: number, order: number, arrivalTime: Date }[] | undefined,
      directions: String[] | undefined,
      tours: Map<string, Tour[]> | undefined,
      departureTimes: Tour[] | undefined
    }[],
    statusState: { status: Status.IDLE } as StatusState
  }),
  getters: {
    getRoutes: (state) => {
      return (id: string) => state.timetables.find(x => x.id === id)?.routes ?? []
    },
    getStopsFrom(state) {
      return (id: string) => state.timetables.find(x => x.id === id)?.stopsFrom ?? [];
    },
    getStopsTo(state) {
      return (id: string) => state.timetables.find(x => x.id === id)?.stopsTo ?? [];
    },
    getDirections(state) {
      return (id: string) => state.timetables.find(x => x.id === id)?.directions ?? [];
    },
    getTours(state) {
      return (id: string, direction: string) => state.timetables.find(x => x.id === id)?.tours?.get(direction) ?? [];
    }
  },
  actions: {
    fetchRoutes(transportType: String, id: string) {
      this.statusState = { status: Status.LOADING };
      return doPost({ MeansOfTransportation: transportType }, "routes").then((data: { routes: RouteLineDetails[] }) => {
        const timeTable = this.timetables.find(x => x.id === id);

        if (timeTable) {
          timeTable.routes = data.routes;
        }
        else {
          this.timetables.push({
            id: id,
            routes: data.routes,
            selectedRoute: undefined,
            stopsFrom: undefined,
            stopsTo: undefined,
            directions: undefined,
            tours: undefined,
            departureTimes: undefined
          })
        }

        this.statusState = {
          status: Status.LOADED
        };
      }).catch(() => {
        this.statusState = {
          status: Status.ERROR,
          message: "Error.Routes",
          retry: () => this.fetchRoutes(transportType, id)
        };
      })
    },
    fetchStops(route: Route, id: string) {
      if (route == null)
        return Promise.resolve();

      const body = {
        lineIds: route?.RouteLineDetails?.lineIds,
        lineName: route?.RouteLineDetails?.lineName,
        lineNumber: route?.RouteLineDetails?.lineNumber
      };

      this.statusState = { status: Status.LOADING };

      return doPost(body, "stops").then((data: { stops: Stop[] }) => {
        const timeTable = this.timetables.find(x => x.id === id);
        if (timeTable) {
          timeTable.stopsFrom = data.stops;
        }
        else {
          this.timetables.push({
            id: id,
            routes: [],
            selectedRoute: undefined,
            stopsFrom: data.stops,
            stopsTo: undefined,
            directions: undefined,
            tours: undefined,
            departureTimes: undefined
          })
        }
        this.statusState = { status: Status.LOADED };
      }).catch(() => {
        this.statusState = {
          status: Status.ERROR,
          message: "Error.Stops",
          retry: () => this.fetchStops(route, id)
        };
      })
    },
    fetchStopsTo(id: string, lineId: number | undefined, lineNumber: string | undefined, tourNumber: number | undefined, stopFrom: number | undefined) {
      const body = {
        lineId: lineId,
        lineNumber: lineNumber,
        tourNumbers: [tourNumber]
      };
      this.statusState = { status: Status.LOADING };
      return doPost(body, "tours").then((data: { stops: { stopName: string, stopNumber: number, order: number, arrivalTime: Date }[] }) => {
        const timeTable = this.timetables.find(x => x.id === id);
        if (timeTable) {
          timeTable.stopsTo = data.stops;
          if (stopFrom) {
            const stop = timeTable.stopsTo.find(s => s.stopNumber === stopFrom);
            timeTable.stopsTo = timeTable.stopsTo.filter(s => s.order > (stop?.order ?? 0));
          }
        }
        this.statusState = { status: Status.LOADED };

      }).catch(() => {
        this.statusState = {
          status: Status.ERROR,
          message: "Error.StopsTo",
          retry: () => this.fetchStopsTo(id, lineId, lineNumber, tourNumber, stopFrom)
        };
      })
    },
    fetchDirectionsAndTours(id: string, lineIds: number[] | undefined, lineNumber: string | undefined, stopNumbers: number[] | undefined, earliestAllowedDeparture: Date | undefined) {
      const body = {
        lineIds: lineIds,
        lineNumber: lineNumber,
        stopNumbers: stopNumbers
      };
      this.statusState = { status: Status.LOADING };
      return doPost(body, "departures").then((data: { tours: Tour[] }) => {
        const tours = data.tours;
        const departureDict = tours.reduce((group, tour) => {
          if (tour.direction && (!earliestAllowedDeparture || !IsEarlier(earliestAllowedDeparture, tour.departureTime))) {
            group.set(tour.direction, [...group.get(tour.direction) || [], tour])
          }
          return group;
        }, new Map<string, Tour[]>());

        const timeTable = this.timetables.find(x => x.id === id);
        if (timeTable) {
          timeTable.directions = Array.from(departureDict.keys());
          timeTable.tours = departureDict;
        }
        this.statusState = { status: Status.LOADED };

      }).catch(() => {
        this.statusState = {
          status: Status.ERROR, message: "Error.DirectionsAndTours",
          retry: () => this.fetchDirectionsAndTours(id, lineIds, lineNumber, stopNumbers, earliestAllowedDeparture)
        };
      })
    },
  },
})