import axios from "axios";
import { AlternativeTripInput, type AlternativeTrips } from "@/models/AlternativeTrip.model";
import type { Route } from "@/models/Route.model";

const baseUrl = import.meta.env.VITE_BACKEND_URL as string;

export default {
    getAlternativeTrips(input: AlternativeTripInput): Promise<AlternativeTrips | undefined> {
        try {
            const url = baseUrl + 'api/v1/travelguarantee/alternativetrips';
            return axios.post(url, {
                startStopName: input.startStopName,
                startStopNumber: input.startStopNumber,
                endStopName: input.endStopName,
                endStopNumber: input.endStopNumber,
                OriginalLineNumber: input.OriginalLineNumber,
                OriginalDepartureTime: input.OriginalDepartureTime,
                LatestAllowedArrivalTime: input.LatestAllowedArrivalTime
            })
                .then(response => {
                    return response.data as AlternativeTrips;
                })
                .catch(error => {
                    console.log(error);
                    throw error;
                });
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    },
    generateAlternativeTripInput(
        routes: Array<Route>,
        originalDepartureTime: Date,
        latestAllowedArrivalTime: Date
    ): AlternativeTripInput {
        const firstleg = routes.at(0);
        const lastLeg = routes.at(routes.length - 1);

        const startStopName = firstleg?.StoppingplaceFrom?.stopName;
        const startStopNumber = firstleg?.StoppingplaceFrom?.stopNumber;
        const endStopName = lastLeg?.StoppingplaceTo?.stopName;
        const endStopNumber = lastLeg?.StoppingplaceTo?.stopNumber;
        const originalLineNumber = firstleg?.RouteLineDetails?.lineNumber;

        if (!startStopName || !endStopName || !originalLineNumber || !startStopNumber || !endStopNumber) {
            throw new Error("Required trip information is missing");
        }

        return new AlternativeTripInput(
            startStopName,
            endStopName,
            startStopNumber,
            endStopNumber,
            originalDepartureTime,
            originalLineNumber,
            latestAllowedArrivalTime
        );
    }
}