import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import type { I18n } from 'vue-i18n/dist/vue-i18n.js';

const i18n = createI18n({
  legacy: false,
  locale: String(import.meta.env.VITE_APP_I18N_LOCALE) || 'da',
  fallbackLocale: String(import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE) || 'da',
  globalInjection: true,
  messages,
  silentTranslationWarn: true,
}) as I18n;

export default i18n