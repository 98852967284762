<template>
    <div class="select-container pb-3">
        <div class="header-container">
            <BaseHeading v-if="label" :heading="label" :icon="icon" classNames="bold" :level=6 />
            <BaseText v-if="description" :text="description" class="description-text"/>
       </div>
        <div :class="{ 'dropdown-disabled': isDisabled }" class="dropdown">
            <button class="btn-lg form-select-lg form-select dropdown-toggle" :class="dropdownClass"
                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :disabled="isDisabled">
                <span class="label-content">{{ selectedLabel }}</span>
            </button>
            <ul class="dropdown-menu" :class="dropdownClass">
                <li v-if="searchable" class="search-input" :class="{ 'mb-2': hasNoOptions }">
                    <input class="form-control" :placeholder="selectedLabel" v-model="searchText" />
                </li>
                <div class="scrollbar-wrapper">
                    <template v-for="(option, index) in currentOptions" :key="index">
                        <li v-if="option.value !== undefined" class="m-2">
                            <button class="dropdown-item btn" @click="selectOption(option)" :title="option.displayValue">
                                <span> {{ option.displayValue }}</span>
                            </button>
                        </li>
                        <hr v-if="index !== currentOptions.length - 1 && option.value != undefined" class="mx-3 my-0">
                    </template>
                </div>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, toRefs, type PropType } from 'vue';
import type OptionModel from '@/models/option.model';
import BaseHeading from './BaseHeading.vue';
import BaseText from './BaseText.vue';
import { Stop, type RouteLineDetails } from '@/models/Route.model';
import { TransportType } from '@/enums/transportType.enum';

const props = defineProps({
    options: {
        type: Array as PropType<OptionModel[]>,
        default: () => []
    },
    modelValue: {
        type: [String, Number, Object as () => RouteLineDetails, TransportType as unknown as () => any, Stop],
        default: null
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
    searchable: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    icon: {
        type: String,
        default: ''
    }
});

const { options, isDisabled, searchable } = toRefs(props);
let selected = ref(props.modelValue != null ? props.modelValue : props.options[0].value);
let selectedLabel = computed(() => getLabel());
let hasNoOptions = computed(() =>
    currentOptions.value.length == 0 ||
    (currentOptions.value.length == 1 && currentOptions.value[0].value == undefined))
const searchText = ref<string | undefined>('');

function getLabel(): string | undefined {
    if (options.value && selected.value) {
        const option = options.value.find(option => option.value === selected.value);
        return option?.displayValue;
    } else {
        return props.options[0].displayValue;
    }
}

const emit = defineEmits(['update:modelValue']);

watch(selected, (newVal, oldVal) => {
    if (newVal !== oldVal && newVal !== props.options[0].value) {
        emit('update:modelValue', newVal);
    }
});

watch(() => props.modelValue, (newValue) => {
    if (newValue === null) {
        selected.value = props.options[0].value;
    }
});

function selectOption(option: OptionModel): void {
    selected.value = option.value;
    emit('update:modelValue', selected.value);
}

const currentOptions = computed(() => {
    if (!searchable.value) return options.value;

    const filtered = [];
    const keyword = (searchText.value ?? '').toLowerCase();
    for (const option of options.value) {
        if (!keyword || option.displayValue?.toLowerCase().includes(keyword)) {
            filtered.push(option);
        }
    }

    return filtered
});

const dropdownClass = computed(() => {
    if (isDisabled.value) return 'grey-dropdown';

    const selectedIndex = props.options.findIndex(option => option.value === selected.value);
    return selectedIndex === 0 ? 'red-dropdown' : 'green-dropdown';
});

</script>

<style lang="scss" scoped>
@import '@/styles/components/base/_baseSelect';
@import '@/styles/components/base/_baseText';
</style>
