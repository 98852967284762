<template>
    <font-awesome-icon class="fa-3x background clickable" :icon="['fas', 'plus-circle']" @click="handleClick" />
    <h4 class="mb-0 p-2">{{ $T.t(text) }}</h4>
</template>

<script setup lang="ts">
import useI18n from '@/composables/useI18n';
import { StandardEmitEvent } from '@/enums/standardEmitEvent.enum';

const props = defineProps({
    text: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    loading: {
        type: Boolean,
        default: false
    },
})

const emit = defineEmits([StandardEmitEvent.CLICK]);

function handleClick(event: Event): boolean | void {
    if (props.loading) {
        return;
    }
    emit(StandardEmitEvent.CLICK, event);
}
const { $T } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/styles/components/base/_baseAddButton';
</style>