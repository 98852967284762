import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'


export default function useLocale(defaultLocale: string = 'da') {
    const currentLocale = ref<string>(defaultLocale);
    const { locale } = useI18n()

    const setLocale = (newlocal: string) => {
        currentLocale.value = newlocal;
        locale.value = newlocal;
        localStorage.setItem("selectedLocale", newlocal);
    }

    onMounted(() => {
        const savedLocale = localStorage.getItem("selectedLocale");
        if (savedLocale) {
            setLocale(savedLocale);
        }
    });

    return {
        currentLocale,
        setLocale
    };
}
