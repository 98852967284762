import './styles/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong, faRotateRight, faCirclePlus, faCheck, faArrowLeftLong, faXmark, faArrowUpRightFromSquare, faCircle } from '@fortawesome/free-solid-svg-icons'

import i18n from '@/plugins/i18n.plugin';

import App from './App.vue'
import router from './router'

//Dont delete this, it says its not used but its needed for bootstrap to work
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App);
library.add(faArrowRightLong);
library.add(faCirclePlus);
library.add(faCheck);
library.add(faArrowLeftLong);
library.add(faXmark);
library.add(faArrowUpRightFromSquare);
library.add(faCircle);
library.add(faRotateRight);

app.use(createPinia()).use(i18n)

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router)
app.mount('#app')