import type { TransportType } from "@/enums/transportType.enum";
import { v4 as uuidv4 } from 'uuid';
import type { Leg } from "./Trip.model";

export class Route {
  Id: string;
  TransportType: TransportType | null = null;
  RouteLineDetails: RouteLineDetails | null = null;
  StoppingplaceFrom: Stop | null = null;
  Direction: string | null = null;
  DepartureTime: string | null = null;
  ArrivalTime: Date | null = null;
  StoppingplaceTo: Stop | null = null;
  TourNumber: string | null = null;
  LineId: number | null = null;

  constructor() {
    this.Id = uuidv4();
  }

  static Empty(): Route {
    return new Route();
  }

  toLeg(): Leg {
    return {
      TransportName: this.RouteLineDetails?.lineName,
      MeansOfTransportation: this.TransportType?.toString(),
      Direction: this.Direction,
      DepartureStopNumber: this.StoppingplaceFrom?.stopNumber,
      ArrivalStopNumber: this.StoppingplaceTo?.stopNumber,
      TourNumber: this.TourNumber ? Number(this.TourNumber) : undefined,
      LineId: this.LineId,
      LineNumber: this.RouteLineDetails?.lineNumber,
      DepartureTime: this.DepartureTime ? new Date(this.DepartureTime) : undefined,
      ArrivalTime: this.ArrivalTime ? new Date(this.ArrivalTime) : undefined
    } as Leg
  }
}
export class RouteLineDetails {
  lineIds: number[] | undefined;
  lineNumber: string | undefined;
  lineName: string | undefined;
}

export class Stop {
  stopName: string | undefined;
  stopNumbers: number[] | undefined;
  stopNumber: number | undefined;;
}
