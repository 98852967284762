import i18n from '@/plugins/i18n.plugin';

export default function useI18n() {
  const $T = i18n.global;
  const currentLocale = i18n.global.locale.value;

  const getMessagesByPrefix = (prefix: string): string[] => {
    const messages = i18n.global.getLocaleMessage(currentLocale) as Record<string, string>;
    const keys = Object.keys(messages).filter(key => key.startsWith(prefix));

    const ctx = {
      normalize: (array: string[]) => array[0]
    };

    return keys.map(key => {
      const funcString = messages[key];
      const messageFunction = eval(`(${funcString})`);
      return messageFunction(ctx);
    });
  };

  return {
    $T,
    getMessagesByPrefix,
  };
}
