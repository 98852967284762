import * as validators from '@vuelidate/validators';
import i18n from '@/plugins/i18n.plugin';

const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

const isTrue = (value: boolean | null) => value == true;

export const fullName = withI18nMessage(validators.helpers.regex(/^[A-Za-zÆØÅæøå][A-Za-zÆØÅæøå\s]*$/));
export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email, { withArguments: true });
export const sameAsEmail = withI18nMessage(validators.sameAs, { withArguments: true });
export const privacyPolicy = withI18nMessage(isTrue);