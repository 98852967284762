<template>
  <div class="main-layout">
    <div :class="{ 'gradient-background-desktop': !isInstantApprovalView }" class="background-no-gradient">
      <header :class="{ 'gradient-background-mobile': isInstantApprovalView }">
        <slot name="header" />
        <WizardNavigationSmall v-if="showWizardNavigation && isInstantApprovalView" />
      </header>
      <main :class="mainClassComputed">
        <slot name="main" />
      </main>
    </div>
    <footer>
      <slot name="footer" />
    </footer>
  </div>
</template>

<script lang="ts" setup >
import { computed } from 'vue';
import WizardNavigationSmall from '../components/WizardNavigationSmall.vue';
import useLayoutLogic from '@/composables/useLayoutLogic';

const {
  showWizardNavigation,
  isInstantApprovalView,
  mainBackgroundClass
} = useLayoutLogic('default');

const mainClassComputed = computed(() => {
  let classes = ['default-main', mainBackgroundClass.value];

  return classes;
});

</script>

<style lang="scss" scoped>
@import '@/styles/screen layouts/screenlayouts';
</style>