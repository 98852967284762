import axios from "axios";
import { Trip, ApprovalResult } from '@/models/Trip.model';

const baseUrl = import.meta.env.VITE_BACKEND_URL as string;

export default {
  fetchTripApproval(trip: Trip): Promise<ApprovalResult | undefined> {
    const url = baseUrl + 'api/v1/travelguarantee/approval';
    return axios.post(url, { Trip: trip })
      .then(response => {
        return response.data as ApprovalResult;
      })
      .catch(error => {
        alert(error.message)
        console.log(error);
        return undefined;
      });
  },

  sendReceipt(customerName: string, email: string, approvalResult: ApprovalResult) {
    try {
      const url = baseUrl + 'api/v1/travelguarantee/recieptmail';
      return axios.post(url, {
        CustomerName: customerName,
        CustomerEmail: email,
        RecieptNumber: approvalResult.referenceId,
        Approved: approvalResult.approved,
        FormUrl: approvalResult.formUrl
      })
        .then(response => {
          return response;
        })
        .catch(error => {
          alert(error.message);
          console.log(error);
          return undefined;
        });
    }
    catch (error) {
      alert(error);
      console.log(error);
      return Promise.resolve(undefined);
    }
  }


}