<template>
    <ResultScreenLayout>
        <template #title>
            <BaseHeading heading="InstantApprovalPage.ResultScreen.rejected.title" classNames="bold" :level=1>
            </BaseHeading>
            <BaseText text="InstantApprovalPage.ResultScreen.rejected.description" classNames="bold"></BaseText>
        </template>
        <template #left-column>
            <BackgroundReasonCard heading="InstantApprovalPage.ResultScreen.reason.rejection.title"
                :reasoning="result?.rejectionReasoning" screen="rejected"></BackgroundReasonCard>
            <AlternativeTripsSection></AlternativeTripsSection>
        </template>
        <template #right-column>
            <ReasonAccordion title="InstantApprovalPage.ResultScreen.Refund.travel.guarantee.when.not.covered"
                :contents="rejectedBullets">
                <template #contact>
                    <BaseText text="InstantApprovalPage.ResultScreen.Refund.contact-midttrafik"
                        linkText="InstantApprovalPage.ResultScreen.Refund.contact-info" url="contactUsUrl">
                    </BaseText>
                </template>
            </ReasonAccordion>
            <div class="heading-box">
                <BaseHeading heading="InstantApprovalPage.ResultScreen.Refund.travel.guarantee" :heading-from-textkey=true
                    classNames="bold" :level=4 />
                <BaseText text="InstantApprovalPage.ResultScreen.Refund.travel.guarantee.apply.for.refund"
                    classNames="bold">
                </BaseText>
                <BaseFormButton text="Button.refusion" class="fa-sharp" icon="arrow-up-right-from-square"
                    :href="result?.formUrl?.toString()">
                </BaseFormButton>
            </div>
            <hr />
        </template>
    </ResultScreenLayout>
</template>

<script setup lang="ts">
import BackgroundReasonCard from '../BackgroundReasonCard.vue';
import ReasonAccordion from '../ReasonAccordion.vue';
import AlternativeTripsSection from './AlternativeTripsSection.vue';
import ResultScreenLayout from '../ResultScreenLayout.vue';
import BaseText from '@/components/base/BaseText.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseFormButton from '@/components/base/BaseFormButton.vue';
import { useResultStore } from '@/stores/resultStore';
import { storeToRefs } from 'pinia';
import useI18n from '@/composables/useI18n';

const store = useResultStore();

const { result } = storeToRefs(store);
const { getMessagesByPrefix } = useI18n();

const rejectedBullets = getMessagesByPrefix("InstantApprovalPage.ResultScreen.content");
</script>

<style scoped lang="scss">
@import '@/styles/components/instantApproval/_instantApproval';
</style>