<template>
    <div class="heading-box">
        <BaseHeading heading="InstantApprovalPage.ResultScreen.receipt.mail.recieptnumber" classNames="bold" :level=5>
        </BaseHeading>
        <h5>{{ result?.referenceId }}</h5>
        <div v-if="isEmailSent">
            <BaseText text="InstantApprovalPage.ResultScreen.receipt.mail.sent"></BaseText>
        </div>
        <div v-else>
            <BaseText text="InstantApprovalPage.ResultScreen.receipt.mail.notSent"></BaseText>
        </div>
        <div>
            <BaseFormButton :text="receiptButtonText" @click=sendEmail :disabled="isEmailSent">
            </BaseFormButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import approvalService from '@/services/approvalService';
import { computed, ref } from 'vue';
import BaseFormButton from '@/components/base/BaseFormButton.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseText from '@/components/base/BaseText.vue';
import { useDetailsStore } from '@/stores/detailsStore';
import { storeToRefs } from 'pinia';
import { useResultStore } from '@/stores/resultStore';

const store = useDetailsStore();
const result = useResultStore().result;
const { customerName, customerEmail } = storeToRefs(store);

var isEmailSent = ref(false);
var receiptButtonText = computed(() => !isEmailSent.value ? "Button.sendReceipt" : "Button.receiptSent")

function sendEmail() {
    if (result) {
        approvalService.sendReceipt(customerName.value, customerEmail.value, result)
            .then((res) => {
                console.log("email response: ", res);
                if (res?.status == 200 || res?.status == 204) {
                    isEmailSent.value = true;
                }
                return res;
            });
    }
}

</script>
