<template>
    <div class="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <a class="nav-link step1" :class="{ active: state.step === 1, 'clickable-step': state.isStepClickable(1) }"
            data-toggle="pill" role="tab" :aria-selected="state.step === 1" @click=state.navigateToStep(1)>{{
                $T.t("InstantApprovalPage.navigation.1")
            }}</a>
        <a class="nav-link step2" :class="{ active: state.step === 2, 'clickable-step': state.isStepClickable(2) }"
            data-toggle="pill" role="tab" :aria-selected="state.step === 2" @click=state.navigateToStep(2)>{{
                $T.t("InstantApprovalPage.navigation.2")
            }}</a>
        <a class="nav-link step3" :class="{ active: state.step === 3, 'clickable-step': state.isStepClickable(3) }"
            data-toggle="pill" role="tab" :aria-selected="state.step === 3" @click=state.navigateToStep(3)>{{
                $T.t("InstantApprovalPage.navigation.3") }}</a>
    </div>
</template>

<script lang="ts" setup >
import useI18n from '@/composables/useI18n';
import { useStepStore } from '@/stores/stepStore';

const { $T } = useI18n();
const state = useStepStore();

</script>
<style lang="scss" scoped>
@import '@/styles/components/wizardNavigation';
</style>
