<template>
    <div class="alert alert-danger">
        <div class="row flex-column flex-md-row align-items-center justify-content-center">
            <div class="col-sm text-center">
                <span>{{ errorMessage }}</span>
                <p v-html="extraMessage" class="m-0"></p>
            </div>
            <div class="col-auto">
                <BaseFormButton text="Error.try.again" @click="onRetry" classNames="error mt-1" icon="fa-rotate-right"
                    :iconClass="iconClass" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useFormattedText from '@/composables/useFormattedText';
import useI18n from '@/composables/useI18n';
import { computed, ref } from 'vue';
import BaseFormButton from './base/BaseFormButton.vue';

const { $T } = useI18n();
const props = defineProps({
    message: {
        type: String,
        default: '',
        required: true
    },
    retry: {
        type: Function,
        default: () => Promise.resolve()
    },
});

const iconClass = ref('')
let errorMessage = computed(() => props.message == '' ? $T.t("Error.Default") : $T.t(props.message))
let extraMessage = useFormattedText({ text: "Error.Refund", url: "applyTravelGuarenteeUrl", textFromTextkey: true, linkText: "midttrafikURL" })

const onRetry = async () => {
    iconClass.value = 'fa-spin';

    await new Promise(f => setTimeout(f, 1000));
    await props.retry()

    iconClass.value = '';  // Remove spin class
};

</script>
  