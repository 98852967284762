<template>
  <div class="heading-box">
    <BaseHeading heading="InstantApprovalPage.ResultScreen.alternativeRoute.title" classNames="bold mb-1" :level=4>
    </BaseHeading>
    <BaseText :text="descriptionText" classNames="bold"></BaseText>
    <ErrorAlert v-if="error" :message="errorMessage" :retry="retryError"></ErrorAlert>
    <div class="pb-3" v-for="(trip, index) in alternativeTrips?.outputTrips" :key="index">
      <AlternativeTripCard :trip="trip"></AlternativeTripCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import AlternativeTripCard from './AlternativeTripCard.vue';
import BaseText from '@/components/base/BaseText.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import { useResultStore } from '@/stores/resultStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useErrorHandler } from '@/composables/useErrorHandler';
import ErrorAlert from '@/components/ErrorAlert.vue';

const { error, errorMessage, retryError } = useErrorHandler(false);
const store = useResultStore();
const { alternativeTrips } = storeToRefs(store);

let descriptionText = computed(() => alternativeTrips.value?.outputTrips && alternativeTrips.value?.outputTrips?.length != 0 ? "InstantApprovalPage.ResultScreen.alternativeRoute.description" : "InstantApprovalPage.ResultScreen.noAlternativeRoute.description")

</script>

<style scoped lang="scss">
@import '@/styles/components/instantApproval/_instantApproval';
</style>