
<template>
  <div class="form-width">
    <slot name="title" />
  </div>

  <div class="row p-2 pb-0">
    <slot name="form" />
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/components/base/_baseFormLayout';
</style>
