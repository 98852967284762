import { computed } from 'vue';
import router from '@/router';
import { useStepStore } from '@/stores/stepStore';
import { useResultStore } from '@/stores/resultStore';

export default function useLayoutLogic(type: 'sidebar' | 'default') {
    const stepStore = useStepStore();
    const step = computed(() => stepStore.$state.step);
    const resultStore = useResultStore();
    const result = computed(() => resultStore.result);

    const showWizardNavigation = computed(() => step.value > 0 && step.value < 4);
    const isNotResultScreen = computed(() => step.value != 5);
    const isInstantApprovalView = computed(() => router.currentRoute.value.name === 'instant approval');

    const mainBackgroundClass = computed(() => {
        if (isNotResultScreen.value) return '';

        const gradientType = result?.value?.approved ? 'approved' : 'rejected';
        return `${gradientType}-gradient-${type}`;
    });

    return {
        showWizardNavigation,
        isInstantApprovalView,
        mainBackgroundClass
    };
}