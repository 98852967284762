<template>
  <div :class="contentClass">
    <ContactInformationScreen v-if="state.step === 1" />
    <RouteScreen v-else-if="state.step === 2" />
    <RouteDetailsScreen v-else-if="state.step === 3" />
    <ResultScreen v-else-if="state.step === 5" />
  </div>
  <LoadingScreen v-if="state.step === 4" />
</template >
  

<script setup lang = "ts">
import LoadingScreen from '@/components/instantApproval/LoadingScreen.vue';
import ResultScreen from '@/components/instantApproval/ResultScreen.vue';
import ContactInformationScreen from '../components/instantApproval/ContactInformation.vue';
import RouteScreen from '../components/instantApproval/RouteScreen.vue';
import RouteDetailsScreen from '../components/instantApproval/RouteDetailsScreen.vue';

import useI18n from '@/composables/useI18n';
import { computed, onMounted, onUnmounted } from 'vue';
import { onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router';
import { useStepStore } from '@/stores/stepStore';

const contentClass = computed(() => {
  return {
    'content-layout': state.step !== 4 && state.step !== 5,
    'content-layout-wide': state.step !== 5,
    'p-4': state.step !== 4,
  };
});

const { $T } = useI18n();

const state = useStepStore();

onMounted(() => {
  window.addEventListener('beforeunload', handleUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', handleUnload);
});

function handleUnload(event: BeforeUnloadEvent) {
  event.preventDefault();
  event.returnValue = 'Are you sure you want to leave this page?';
  state.reset();
}


// Comment this out to not block hot reload when developing.
onBeforeRouteUpdate((to, from, next) => {
  const newStep = Number(to.params.step);
  const oldStep = state.step;

  if (newStep < oldStep && !window.confirm('Are you sure you want to navigate back?')) {
    next(false);
  } else {

    state.step = newStep;
    next();
    state.reset();
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (window.confirm($T.t("InstantApprovalPage.navigation.back.confirm"))) {
    next();
    state.reset();
  } else {
    next(false);
  }
});

</script>

<style scoped lang="scss">
@import '@/styles/views/instantApprovalView';
</style>
