<template>
    <div class="card mb-4">
        <div class="card-title">
            <BaseHeading
                :heading="$T.t('InstantApprovalPage.RouteLineDetails.heading') + route.RouteLineDetails?.lineNumber"
                :headingFromTextkey="false" classNames="bold" :level="4" />
        </div>
        <BaseSelect label="InstantApprovalPage.routeDetailsCard.StoppingplaceFrom.label" :icon="questionIcon"
            v-model="route.StoppingplaceFrom" @update:modelValue="value => {
                updateDepartures(value);
                resetOtherFields('StoppingplaceFrom');
            }" :options="getOptions('StoppingplaceFrom', route.RouteLineDetails != null)"
            :is-disabled="disabled || loading" />

        <BaseSelect label="InstantApprovalPage.routeDetailsCard.Direction.label" :icon="questionIcon"
            v-model="route.Direction"
            @update:modelValue="value => { route.Direction = value; resetOtherFields('Direction'); }"
            :options="getOptions('Direction', route.StoppingplaceFrom != null)"
            :is-disabled="disabled || stoppingplaceFromIsEmpty || loading" />

        <BaseSelect label="InstantApprovalPage.routeDetailsCard.DepartureTime.label" description="InstantApprovalPage.routeDetailsCard.DepartureTime.description" v-model="route.DepartureTime"
            @update:modelValue="value => {
                route.DepartureTime = value.departureTime;
                route.TourNumber = value.tourNumber;
                route.LineId = value.lineId
                updateStopsTo(value, value.stopNumber);
                setDepartureStopNumber(value.stopNumber);
                resetOtherFields('DepartureTime');
            }" :options="getOptions('DepartureTime', route.Direction != null)"
            :is-disabled="disabled || directionIsEmpty || loading" />
        
        <BaseSelect label="InstantApprovalPage.routeDetailsCard.StoppingplaceTo.label" v-model="route.StoppingplaceTo"
            @update:modelValue="value => { route.StoppingplaceTo = value; route.ArrivalTime = value.arrivalTime }"
            :options="getOptions('StoppingplaceTo', route.DepartureTime != null)"
            :is-disabled="disabled || departureTimeIsEmpty || loading" />

        <BaseHeading heading="InstantApprovalPage.routeDetailsCard.expectedTime" classNames="bold" :level="5" />
        <BaseHeading
            :heading="route.ArrivalTime ? toHourAndMinutes(route.ArrivalTime.toString()) : $T.t('InstantApprovalPage.routeDetailsCard.expectedTime.placeholder')"
            :headingFromTextkey="false" classNames="m-0 bold" :level=6 />
    </div>
</template>

<script setup lang="ts">
import useI18n from '@/composables/useI18n';
import BaseSelect from '../../base/BaseSelect.vue';
import BaseHeading from '../../base/BaseHeading.vue';
import { computed, watch } from 'vue';
import { useTimeTableStore } from '@/stores/timeTablesStore';
import type { Tour } from '@/models/Trip.model';
import { toHourAndMinutes } from '@/utils/datetime-util'
import { useDetailsStore } from '@/stores/detailsStore';
import questionIcon from '@/assets/images/question.svg';
import { Stop } from '@/models/Route.model';
import { Status } from '@/enums/status.enum';
import useOptions from '@/composables/useOptions';

const store = useDetailsStore();
const timeTableStore = useTimeTableStore();

const { $T } = useI18n();

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    disabled: {
        type: Boolean,
        required: false,
        default: true
    },
    earliestDeparture: {
        type: Date,
        required: false,
        default: undefined
    }
});

const route = store.routeById(props.id);

const stoppingplaceFromIsEmpty = computed(() => !route.StoppingplaceFrom);
const directionIsEmpty = computed(() => !route.Direction);
const departureTimeIsEmpty = computed(() => !route.DepartureTime);
const loading = computed(() => timeTableStore.statusState.status === Status.LOADING);

watch(
    () => timeTableStore.getStopsFrom(props.id),
    (newStops) => {
        if (!newStops?.length) {
            timeTableStore.fetchStops(route, props.id);
        }
    },
    { immediate: true }
);
const { getOptions } = useOptions(props.id, route);

function updateDepartures(chosenStop: Stop) {
    const stopNumbers = chosenStop.stopNumbers;
    const lineIds = route.RouteLineDetails?.lineIds;
    const lineNo = route.RouteLineDetails?.lineNumber;

    timeTableStore.fetchDirectionsAndTours(props.id, lineIds, lineNo, stopNumbers, props.earliestDeparture)
}

function updateStopsTo(chosenDeparture: Tour, chosenStopFrom: number) {
    const lineId = chosenDeparture.lineId;
    const lineNo = route?.RouteLineDetails?.lineNumber;

    timeTableStore.fetchStopsTo(props.id, lineId, lineNo, chosenDeparture.tourNumber, chosenStopFrom)
}

function setDepartureStopNumber(stopNumber: number) {
    if (route.StoppingplaceFrom) {
        route.StoppingplaceFrom.stopNumber = stopNumber;
    }
}

function resetOtherFields(field: String) {
    switch (field) {
        case 'StoppingplaceFrom':
            route.Direction = null;
            route.DepartureTime = null;
            route.StoppingplaceTo = null;
            route.ArrivalTime = null
            break
        case 'Direction':
            route.DepartureTime = null;
            route.StoppingplaceTo = null;
            route.ArrivalTime = null
            break
        case 'DepartureTime':
            route.StoppingplaceTo = null;
            route.ArrivalTime = null
            break
    }
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/components/instantApproval/routeCards';
</style>
