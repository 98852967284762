<template>
    <div class="mobile-wizard" id="v-pills-tab-mobile" role="tablist">
        <ul class="nav nav-pills">
            <li v-for="step in 3" :key="step" class="nav-step" :class="{ 'clickable-step': state.isStepClickable(step) }">
                <a class="nav-link" :class="{ active: state.step === step }" data-toggle="pill"
                    @click=state.navigateToStep(step)>{{
                        step }}</a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup >
import { useStepStore } from '@/stores/stepStore';
const state = useStepStore();

</script>
<style lang="scss" scoped>
@import '@/styles/components/wizardNavigation';
</style>

  