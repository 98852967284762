<template>
    <BaseHeading :heading=label classNames="bold" :level=6></BaseHeading>
    <div class="input-group-lg mb-3">
        <input :type="type" class="form-control border-dark border-1"
            :class="[{ 'form-control-error': v$[vuelidateField].$error }]" v-model="v$[vuelidateField].$model"
            aria-label="Large" aria-describedby="inputGroup-sizing-lg">
        <template v-if="v$[vuelidateField].$dirty">
            <div v-for="error in v$[vuelidateField].$silentErrors" :key="error.$message.toString()" class="error-message">
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup >
import { computed, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import useI18n from '@/composables/useI18n';
import BaseHeading from './BaseHeading.vue';

const props = defineProps({
    label: {
        type: String,
        default: ''
    },
    modelValue: {
        type: [String, Number],
        default: ''
    },
    rules: {
        type: Object,
        default: null
    },
    type: {
        type: String,
        default: 'text'
    }
})

const fieldValue = ref(props.modelValue);
const { $T } = useI18n();
const vuelidateField = computed(() => $T.t(props.label));
const v$ = useVuelidate({ [vuelidateField.value]: props.rules }, { [vuelidateField.value]: fieldValue });

const emit = defineEmits(['update:modelValue']);

watch(fieldValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal);
    }
});

</script>

<style lang="scss" scoped>
@import '@/styles/components/base/_baseInputField';
</style>