<template>
    <p v-if="url && linkText" :class="classNames" v-html="content"></p>
    <p v-else :class="classNames" v-html="plainText"></p>
</template>

<script lang="ts" setup>
import useFormattedText from '@/composables/useFormattedText';
import useI18n from '@/composables/useI18n';
import { computed } from 'vue';

const { $T } = useI18n();
const props = defineProps({
    text: { type: String, required: true },
    linkText: { type: String, default: null },
    url: { type: String, default: null },
    textFromTextkey: { type: Boolean, default: true },
    classNames: {
        type: String,
        default: ''
    },
});

const content = props.url && props.linkText ?
    useFormattedText({ text: props.text, linkText: props.linkText, url: props.url, textFromTextkey: props.textFromTextkey }) : '';

const plainText = computed(() => {
    return props.textFromTextkey ? $T.t(props.text) : props.text;
});

</script>

<style scoped lang="scss">
@import '@/styles/components/base/_baseText';
</style>