<template>
    <div>
        <BaseHeading heading="Homepage.faq.title" classNames="bold white" :level=3></BaseHeading>
        <hr>
        <div class="accordion" id="faqAccordion">
            <div v-for="(label, index) in labels" :key="index">
                <FaqAccordionButton :title="label" :index="index">
                    <template #answer>
                        <FaqAnswer :answerFor="label">
                        </FaqAnswer>
                    </template>
                </FaqAccordionButton>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts" setup>
import FaqAccordionButton from '@/components/home/FaqAccordionButton.vue'
import BaseHeading from '../base/BaseHeading.vue';
import FaqAnswer from './FaqAnswer.vue';

const labels = [
    "Homepage.faq.how.to.use",
    "Homepage.faq.when.apply",
    "Homepage.faq.when.not.apply",
    "Homepage.faq.what.give"
]

</script>
<style lang="scss" scoped>
@import '@/styles/components/home/faqAccordion';
</style>
