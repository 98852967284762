import type { ApprovalResult } from "@/models/Trip.model";

export enum Status {
    IDLE = 'idle',
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error'
}

export type StatusState = { status: Status.IDLE | Status.LOADING | Status.LOADED } | { status: Status.ERROR, message: string, retry?: () => Promise<void | ApprovalResult> };
