<template>
    <div class="p-2 row col-12">
        <div class="col-auto">
            <slot name="icon"></slot>
        </div>
        <div class="col d-flex align-items-center">
            <slot name="text"></slot>
        </div>
    </div>
</template>
      