<template>
    <ResultScreenLayout>
        <template #title>
            <BaseHeading heading="InstantApprovalPage.ResultScreen.approved.title" classNames="bold" :level=1>
            </BaseHeading>
            <BaseText text="InstantApprovalPage.ResultScreen.approved.description"></BaseText>
        </template>
        <template #left-column>
            <ReimbursementInfoCard></ReimbursementInfoCard>
            <div class="mb-2">
                <BackgroundReasonCard heading="InstantApprovalPage.ResultScreen.reason.title" :reasoning="reasoning"
                    screen="approved">
                </BackgroundReasonCard>
            </div>
        </template>
        <template #right-column>
            <BaseHeading heading="InstantApprovalPage.ResultScreen.Refund.travel.guarantee" :heading-from-textkey=true
                classNames="bold" :level=4 />
            <BaseText text="InstantApprovalPage.ResultScreen.Refund.travel.guarantee.description"></BaseText>
            <ReasonAccordion class="mb-3" title="InstantApprovalPage.ResultScreen.Refund.not.covered.title"
                :contents="approvedBullets">
            </ReasonAccordion>
            <BaseFormButton text="Button.refusion" class="fa-sharp" icon="arrow-up-right-from-square"
                :href="result?.formUrl?.toString()">
            </BaseFormButton>
            <hr />
        </template>
    </ResultScreenLayout>
</template>

<script setup lang="ts">
import BackgroundReasonCard from '../BackgroundReasonCard.vue';
import ReimbursementInfoCard from './ReimbursementInfoCard.vue';
import ReasonAccordion from '../ReasonAccordion.vue';
import ResultScreenLayout from '../ResultScreenLayout.vue';
import BaseText from '@/components/base/BaseText.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseFormButton from '@/components/base/BaseFormButton.vue';
import { useResultStore } from '@/stores/resultStore';
import { storeToRefs } from 'pinia';
import useI18n from '@/composables/useI18n';

const store = useResultStore();
const { result } = storeToRefs(store);
const { getMessagesByPrefix } = useI18n();

const reasoning = result.value?.approvalReasoning ? [result.value?.approvalReasoning] : undefined;

const approvedBullets = getMessagesByPrefix("InstantApprovalPage.ResultScreen.approved.content");

</script>