<template>
  <div class="container">
    <PageButton class="col-12" title="Homepage.instantapproval.label" subtitle="Homepage.subtitle"
      :onClick="handleInstantApprovalClick">
      <template #icon>
        <ArrowForwardIcon></ArrowForwardIcon>
      </template>
    </PageButton>
    <PageButton class="col-12" title="Homepage.refund" :onClick="openRefusionPage">
      <template #icon>
        <ArrowFromSquareIcon></ArrowFromSquareIcon>
      </template>
    </PageButton>
    <PageButton class="col-12" title="Homepage.traficinfo" :onClick="openTrafficinfoPage">
      <template #icon>
        <ArrowFromSquareIcon></ArrowFromSquareIcon>
      </template>
    </PageButton>
    <PageButton class="col-12" title="Homepage.contact" :onClick="openContactPage" />
  </div>
</template>

<script lang ="ts" setup>
import { useRouter } from 'vue-router';
import PageButton from './PageButton.vue';
import ArrowForwardIcon from '@/components/icons/ArrowForwardIcon.vue';
import ArrowFromSquareIcon from '@/components/icons/ArrowFromSquareIcon.vue';
import useI18n from '@/composables/useI18n';

const router = useRouter()
const path = router.resolve({ name: 'instant approval' }).path;
const { $T } = useI18n();

const handleInstantApprovalClick = () => {
  router.push(path);
}

const openRefusionPage = () => {
  window.open($T.t("applyTravelGuarenteeUrl"), "_blank");
}

const openTrafficinfoPage = () => {
  window.open($T.t("trafficinfoUrl"), "_blank");
}

const openContactPage = () => {
  window.open($T.t("contactUrl"), "_blank");
}

</script>