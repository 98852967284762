<template>
  <component :is="tag" :class="classNames">
    <a v-if="link" :href="link" class="link">{{ heading }}</a>
    <span v-else>{{ heading }}</span>
    <img v-if="showIcon && icon" class="logo img-fluid" :src="icon" />
  </component>
</template>
    
<script lang="ts" setup>
import useI18n from '@/composables/useI18n';
import { computed } from 'vue';

const { $T } = useI18n();

const props = defineProps({
  heading: {
    type: String,
    required: true
  },
  link: String,
  icon: {
    type: String,
    default: null
  },
  level: {
    type: Number,
    default: 1,
  },
  headingFromTextkey: { type: Boolean, default: true },
  classNames: String,
  showIcon: Boolean
});

// Computes the tag based on the level prop
const tag = computed(() => `h${props.level}`);

const heading = computed(() => {
  return props.headingFromTextkey ? $T.t(props.heading) : props.heading;
});
</script>
    
<style scoped lang="scss">
@import '@/styles/components/base/_baseText';
@import '@/styles/components/base/_baseHeading';
</style>
