<template>
    <div :class="['card', screen]">
        <div class=card-body>
            <BaseIconText> <template #icon>
                    <div class="icon-container">
                        <InfoIcon></InfoIcon>
                    </div>
                </template>
                <template #text>
                    <BaseHeading :heading="props.heading" classNames="h6 bold mb-0">
                    </BaseHeading>
                </template>
            </BaseIconText>
            <div v-for="(reason, index) in (props.reasoning)" :key="index">
                <BaseIconText>
                    <template #icon>
                        <div class="icon-container">
                            <BusIcon v-if="reason.transportType == 'Bus'"></BusIcon>
                            <TrainIcon v-if="reason.transportType == 'Train'"></TrainIcon>
                        </div>
                    </template>
                    <template #text>
                        <BaseText :text="(localizedMessage(reason))" class="m-0" :textFromTextkey=false></BaseText>
                    </template>
                </BaseIconText>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import InfoIcon from '@/components/icons/InfoIcon.vue';
import BaseIconText from '@/components/base/BaseIconText.vue';
import BaseText from '@/components/base/BaseText.vue';
import BusIcon from '@/components/icons/BusIcon.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import TrainIcon from '@/components/icons/TrainIcon.vue';
import { Reason } from '@/models/Trip.model';
import useLocale from '@/composables/useLocale';

const { currentLocale } = useLocale();

const localizedMessage = (reason: Reason) => {
    return reason.message?.[currentLocale.value] || reason.message?.da || '';
};

const props = defineProps({
    reasoning: {
        type: Array<Reason>,
        default: []
    },
    heading: {
        type: String,
        default: ''
    },
    screen: {
        type: String,
        default: ''
    },
})
</script>

<style scoped lang="scss">
@import '@/styles/components/instantApproval/result/backgroundReasonCard';
</style>