<template>
  <div class="main-layout">
    <div class="sidebar-page">
      <aside class="gradient-background-desktop">
        <header>
          <slot name="header" />
        </header>
        <div>
          <WizardNavigation v-if="showWizardNavigation && isInstantApprovalView" />
        </div>
      </aside>
      <main :class="['sidebar-main', mainBackgroundClass]">
        <slot name="main" />
      </main>
    </div>
    <footer>
      <slot name="footer" />
    </footer>
  </div>
</template>

<script setup lang="ts">
import WizardNavigation from '../components/WizardNavigation.vue';
import useLayoutLogic from '@/composables/useLayoutLogic';

const {
  showWizardNavigation,
  isInstantApprovalView,
  mainBackgroundClass
} = useLayoutLogic('sidebar');

</script>

<style lang="scss" scoped>
@import '@/styles/screen layouts/screenlayouts';
</style>
