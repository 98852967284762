import { Route } from '@/models/Route.model';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDetailsStore = defineStore('details', () => {
  const customerName = ref('');
  const customerEmail = ref('');
  const confirmEmail = ref('');
  const privacyAccepted = ref(false)
  const routes = ref([Route.Empty()]);

  function routesAsTrip() {
    return {
      Legs: routes.value.map(x => x.toLeg())
    };
  }

  function resetRouteDetails() {
    routes.value.forEach(route => {
      route.StoppingplaceFrom = null;
      route.Direction = null;
      route.DepartureTime = null;
      route.ArrivalTime = null;
      route.StoppingplaceTo = null;
      route.TourNumber = null;
      route.LineId = null;
    });
  }

  function resetRoutes() {
    routes.value = [Route.Empty()]
  }

  function resetContactInfo() {
    customerEmail.value = '';
    confirmEmail.value = '';
    customerName.value = '';
    privacyAccepted.value = false;
  }

  function resetAll() {
    resetRoutes()
    resetContactInfo()
  }

  function addRoute() {
    routes.value.push(Route.Empty());
  }

  function deleteRoute(routeId: string) {
    const index = routes.value.findIndex(route => route.Id === routeId);
    if (index !== -1) {
      routes.value.splice(index, 1);
    }
  }

  function routeById(routeId: string): Route {
    return routes.value.find(route => route.Id === routeId) ?? Route.Empty();
  }

  function numberOfRoutes() {
    return routes.value.length;
  }

  return { customerName, customerEmail, confirmEmail, routes, privacyAccepted, addRoute, deleteRoute, routesAsTrip, resetRouteDetails, resetAll, routeById, numberOfRoutes }
});