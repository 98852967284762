<template>
    <BaseFormLayout>
        <template #title>
            <div class="p-2">
                <BaseHeading heading="InstantApprovalPage.contactInformation.header" classNames="bold" :level=4 />
                <BaseText text="InstantApprovalPage.contactInformation.subHeader" classNames="m-0 bold" />
            </div>
        </template>
        <template #form>
            <BaseHeading heading="InstantApprovalPage.contactInformation.contactinfo.header" classNames="bold"
                :icon=questionIcon :level=4 />
            <div class="col-12 col-md-6">
                <BaseInputField label="InstantApprovalPage.contactInformation.input.fullName" :rules="rules.name"
                    v-model="customerName" @update:modelValue="value => { customerName = value; }" />
                <BaseInputField label="InstantApprovalPage.contactInformation.mail.enter" type="email" :rules="rules.email"
                    v-model="customerEmail" @update:modelValue="value => { customerEmail = value; }" />
                <BaseInputField label="InstantApprovalPage.contactInformation.mail.confirm" type="email"
                    :rules="rules.confirm" v-model="confirmEmail" @update:modelValue="value => { confirmEmail = value; }" />
                <BaseCheckbox label="InstantApprovalPage.contactInformation.privacy.policy" :rules="rules.privacyPolicy"
                    v-model="privacyAccepted" @update:modelValue="value => { privacyAccepted = value; }">
                    <template #text>
                        <BaseText text="InstantApprovalPage.contactInformation.privacy.policy" classNames="m-3"
                            linkText="InstantApprovalPage.contactInformation.privacy.policy-replace" url="privacyPolicyUrl">
                        </BaseText>
                    </template>
                </BaseCheckbox>
            </div>
        </template>
    </BaseFormLayout>
    <div class="p-2">
        <BaseFormButton text="Button.next" icon="fa-arrow-right-long" :disabled="v$.$invalid" @click="forward">
        </BaseFormButton>
    </div>
</template >

<script setup lang="ts">
import { email, required, fullName, sameAsEmail, privacyPolicy } from '@/utils/validation-util';
import useVuelidate from '@vuelidate/core';
import BaseFormLayout from '../base/BaseFormLayout.vue';
import BaseInputField from '../base/BaseInputField.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseHeading from '../base/BaseHeading.vue';
import BaseFormButton from '../base/BaseFormButton.vue';
import { useStepStore } from '@/stores/stepStore';
import { useDetailsStore } from '@/stores/detailsStore';
import BaseText from '../base/BaseText.vue';
import questionIcon from '@/assets/images/question.svg';
import { storeToRefs } from 'pinia';

const state = useStepStore();
const detailsState = useDetailsStore();
const { customerName, customerEmail, confirmEmail, privacyAccepted } = storeToRefs(detailsState);

let rules = {
    name: { required, fullName },
    email: { required, email },
    confirm: { required, email, sameAsEmail: sameAsEmail(customerEmail) },
    privacyPolicy: { privacyPolicy }
};

const v$ = useVuelidate();

function forward() {
    state.next();
}
</script>
