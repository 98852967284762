<template>
    <div>
        <div v-if="error">
            <ErrorAlert :message="errorMessage" :retry="retryError"></ErrorAlert>
        </div>
        <BaseFormLayout>
            <template #title>
                <div class="p-2">
                    <BaseHeading heading="InstantApprovalPage.tripDetails.header" classNames="bold" :level=4
                        :icon="questionIcon" />
                </div>
            </template>
            <template #form>
                <div class="col-12 col-lg-6">
                    <div class="pb-4 " v-for="route in routes" :key="route.Id">
                        <RouteCard :id="route.Id" />
                    </div>
                </div>
                <BaseHeading heading="InstantApprovalPage.tripDetails.switching" classNames="bold" :level=6 />
                <div class="d-flex pb-1 pt-3">
                    <BaseAddButton text="InstantApprovalPage.tripDetails.addRoute" @click="addRoute"></BaseAddButton>
                </div>
                <div class="button-container col-12 col-lg-6">
                    <div class="py-2">
                        <BaseFormButton text="Button.previous" icon="fa-arrow-left-long" @click="previous"
                            :isIconAfter=false>
                        </BaseFormButton>
                    </div>
                    <div class="py-2">
                        <BaseFormButton text="Button.next" icon="fa-arrow-right-long" :disabled="!allTripDetailsValid"
                            @click="forward">
                        </BaseFormButton>
                    </div>
                </div>
            </template>
        </BaseFormLayout>
    </div>
</template >

<script setup lang="ts">
import { computed } from 'vue';
import BaseFormLayout from '../base/BaseFormLayout.vue';
import RouteCard from './cards/RouteCard.vue';
import BaseHeading from '../base/BaseHeading.vue';
import BaseFormButton from '../base/BaseFormButton.vue';
import BaseAddButton from '../base/BaseAddButton.vue';
import { useDetailsStore } from '@/stores/detailsStore';
import { useStepStore } from '@/stores/stepStore';
import { storeToRefs } from 'pinia';
import questionIcon from '@/assets/images/question.svg';
import ErrorAlert from '../ErrorAlert.vue';
import { useErrorHandler } from '@/composables/useErrorHandler';

const store = useDetailsStore();
const state = useStepStore();
const { error, errorMessage, retryError } = useErrorHandler(true);
const { routes } = storeToRefs(store);

const allTripDetailsValid = computed(() => {
    return routes.value.every(route => route.TransportType !== null && route.RouteLineDetails !== null);
});

function forward() {
    state.next();
}

function previous() {
    state.back();
}

function addRoute() {
    store.addRoute();
}

</script>

<style lang="scss" scoped>
@import '@/styles/components/instantApproval/_instantApproval';
</style>
