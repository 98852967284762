import { ref, onMounted, onUnmounted } from 'vue';

export default function useWindowWidth() {
    const windowWidth = ref(window.innerWidth);

    // Update windowWidth when the window is resized
    const updateWindowWidth = () => { windowWidth.value = window.innerWidth; };

    onMounted(() => window.addEventListener('resize', updateWindowWidth));
    onUnmounted(() => window.removeEventListener('resize', updateWindowWidth));

    return { windowWidth };
}
