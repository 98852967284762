import { computed } from 'vue';
import useI18n from './useI18n';

export default function useFormattedText({
    text,
    linkText,
    url,
    textFromTextkey = true,
    icon
}: {
    text: string,
    linkText?: string,
    url?: string,
    textFromTextkey?: boolean,
    icon?: string,
}) {
    const { $T } = useI18n();

    return computed(() => {
        const textContent = textFromTextkey ? $T.t(text) : text;
        const linkTemplate = url && linkText
            ? `<a href="${$T.t(url)}" class="text-reset" target="_blank" rel="noopener noreferrer">${$T.t(linkText)}</a>`
            : '';
        const finalText = textContent.replace($T.t(linkText || ''), linkTemplate);

        if (icon) {
            return `${finalText} <img class="logo img-fluid" src="${icon}" />`;
        }

        return finalText;
    });
}