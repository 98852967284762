<template>
    <div v-if="state.result?.approved">
        <ApprovedScreen></ApprovedScreen>
    </div>
    <div v-if="!state.result?.approved">
        <RejectedScreen></RejectedScreen>
    </div>
</template>

<script setup lang="ts">
import { useResultStore } from '@/stores/resultStore';
import ApprovedScreen from './result/approved/ApprovedScreen.vue';
import RejectedScreen from './result/rejected/RejectedScreen.vue';

const state = useResultStore();

</script>
