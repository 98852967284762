<template>
  <div class="col-12 mb-4 d-flex">
    <button class="btn btn-outline btn-lg btn-block" @click="handleClick">
      <div class="content-container">
        <div class="p-1">
          <BaseHeading :heading="title" class-names="bold white m-0" :level="5"></BaseHeading>
          <BaseText v-if="subtitle" :text="subtitle" class-names="white"></BaseText>
        </div>
        <div v-if="!subtitle" class="icon-container">
          <slot name="icon"></slot>
        </div>
        <div v-else-if="subtitle" class="icon-container-subtitle">
          <slot name="icon"></slot>
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
import BaseText from '../base/BaseText.vue';
import BaseHeading from '../base/BaseHeading.vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: false,
  },
  onClick: {
    type: Function,
    default: () => { }
  },
})

const handleClick = (event: any) => {
  props.onClick(event);
}
</script>


<style lang="scss" scoped>
@import '@/styles/components/home/pageButton';
</style>