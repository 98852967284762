<template>
    <div class="accordion" id="myAccordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse"
                    aria-expanded="true" aria-controls="collapse">
                    {{ $T.t(props.title) }}
                </button>
            </h2>
            <div id="collapse">
                <div class="accordion-collapse collapse show" aria-labelledby="heading" data-bs-parent="#myAccordion">
                    <div class="accordion-body">
                        <BaseBulletList :bullets=contents :textFromTextkey=false></BaseBulletList>
                        <slot name="contact"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import useI18n from '@/composables/useI18n';
import { onMounted } from 'vue';
import * as bootstrap from 'bootstrap';
import BaseBulletList from '@/components/base/BaseBulletList.vue';

// Needed for bootstrap accordion not to bug out
onMounted(() => {
    const element = document.getElementById('collapse') as HTMLElement;
    new bootstrap.Collapse(element);
});

const { $T } = useI18n();

const props = defineProps({
    title: {
        type: String, required: true
    },
    contents: {
        type: Array<string>,
        required: true
    }
})

</script>

<style scoped lang="scss">
@import '@/styles/components/instantApproval/result/reasonAccordion';
</style>
