import { defineStore } from 'pinia';
import { useResultStore } from './resultStore';
import { useDetailsStore } from './detailsStore';
import { Status } from '@/enums/status.enum';

export const useStepStore = defineStore({
    id: 'step',
    state: () => ({
        step: 1,
    }),
    actions: {
        next() {
            this.step++;
        },
        back() {
            if (this.step != 1) {
                this.navigateToStep(this.step - 1);
            }
        },
        reset() {
            this.step = 1;
            useDetailsStore().resetAll()
        },
        async submit() {
            const resultStore = useResultStore();
            this.step = 4; //Instead of next since it might be retry after error
            try {
                const approvalResult = await resultStore.fetchTripApproval();

                if (!approvalResult.approved && resultStore.statusState.status != Status.ERROR) {
                    await resultStore.fetchAlternativeTrips();
                }
                else if (approvalResult.approved) {
                    this.step = 5
                }

            } catch (error) {
                resultStore.statusState = {
                    status: Status.ERROR,
                    message: "Error.TripApproval",
                    retry: () => this.submit()
                };
            }
        },
        navigateToStep(navigateToStep: number) {
            if (this.step === 3 && navigateToStep === 2) {
                useDetailsStore().resetRouteDetails()
            }
            if (this.step > navigateToStep) {
                this.step = navigateToStep
            }
        },
        isStepClickable(step: number) {
            return this.step > step;
        }

    }
});

