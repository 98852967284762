import type { DateTime } from "luxon"

export class AlternativeTrips {
    outputTrips: Array<AlternativeTrip> | undefined;
}

export class AlternativeTrip {
    arrivalTime: DateTime | undefined;
    departureTime: DateTime | undefined;
    TravelTimeInMinutes: Number | undefined;
    IsArrivaTrip: Boolean | undefined;
    IsCancelled: Boolean | undefined;
    outputTripLegs: Array<AlternativeTripLeg> | undefined;

    static Empty(): AlternativeTrip {
        return new AlternativeTrip();
    }
}

export class AlternativeTripLeg {
    tripId: number;
    legOrder: number;
    name: string;
    startStop: AlternativeTripStop;
    endStop: AlternativeTripStop;
    departureTime: DateTime;
    arrivalTime: DateTime;
    isTrainLeg: boolean;
    constructor(
        tripId: number,
        legOrder: number,
        name: string,
        startStop: AlternativeTripStop,
        endStop: AlternativeTripStop,
        departureTime: DateTime,
        arrivalTime: DateTime,
        isTrainLeg: boolean
    ) {
        this.tripId = tripId;
        this.legOrder = legOrder;
        this.name = name;
        this.startStop = startStop;
        this.endStop = endStop;
        this.departureTime = departureTime;
        this.arrivalTime = arrivalTime;
        this.isTrainLeg = isTrainLeg;
    }
}


export class AlternativeTripStop {
    rejseplanensId: Number;
    name: string;

    constructor(
        rejseplanensId: number,
        name: string,
    ) {
        this.rejseplanensId = rejseplanensId;
        this.name = name;
    }
}

export class AlternativeTripInput {
    startStopName: string;
    startStopNumber: number;
    endStopName: string;
    endStopNumber: number;
    OriginalDepartureTime: Date;
    OriginalLineNumber: string;
    LatestAllowedArrivalTime: Date;

    constructor(
        startStopName: string,
        endStopName: string,
        startStopNumber: number,
        endStopNumber: number,
        OriginalDepartureTime: Date,
        OriginalLineNumber: string,
        LatestAllowedArrivalTime: Date
    ) {
        this.startStopName = startStopName;
        this.endStopName = endStopName;
        this.startStopNumber = startStopNumber;
        this.endStopNumber = endStopNumber;
        this.OriginalDepartureTime = OriginalDepartureTime;
        this.OriginalLineNumber = OriginalLineNumber;
        this.LatestAllowedArrivalTime = LatestAllowedArrivalTime;
    }
}
