<template>
  <component :is="layoutComponent">
    <template #header>
      <MainHeader />
    </template>
    <template #main>
      <router-view />
    </template>
    <template #footer>
      <MainFooter />
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { breakpoints } from '@/config/config';
import { useRoute } from 'vue-router';
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
import DefaultLayout from './screen layouts/DefaultLayout.vue';
import SidebarLayout from './screen layouts/SidebarLayout.vue';
import useWindowWidth from '@/composables/useWindowWidth';

const route = useRoute();

const { windowWidth } = useWindowWidth();

const layoutComponent = computed(() => {
  return route.meta.sidebar && windowWidth.value > breakpoints.desktop && route.name !== 'home' ? SidebarLayout : DefaultLayout;
});
</script>

