<template>
    <div id="header" class="d-flex">
        <div class="col-6 d-flex align-items-start">
            <a @click="navigateHome"><img alt="Midttrafik logo" class="logo-left"
                    src="@/assets/images/logo_white.svg" /></a>
        </div>
        <div class="col-6 d-flex justify-content-end">
            <a @click="switchLocale"><img :alt="altText" class="logo-right" :src="languageIcon"></a>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useLocale from '@/composables/useLocale';
import { computed } from 'vue';
import danishIcon from '@/assets/images/change_language_danish.svg';
import englishIcon from '@/assets/images/change_language_english.svg';
import router from '@/router';

const { currentLocale, setLocale } = useLocale();

const switchLocale = () => {
    const newLocale = currentLocale.value === 'en' ? 'da' : 'en';
    setLocale(newLocale);
    location.reload();
}

const path = router.resolve({ name: 'home' }).path;

const navigateHome = () => {
    router.push(path);
}

const languageIcon = computed(() => {
    return currentLocale.value === 'en'
        ? danishIcon
        : englishIcon;
});

const altText = computed(() => {
    return currentLocale.value === 'en'
        ? 'Change to Danish'
        : 'Change to English';
});

</script>

<style scoped lang="scss">
@import '@/styles/components/mainHeader';
</style>
