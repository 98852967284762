import { DateTime } from 'luxon';

export function toHourAndMinutes(isoString?: string | null): string {
    if (!isoString) return '';
    return DateTime.fromISO(isoString).toFormat('HH:mm');
}

export function toDanishDateTime(isoString: string): DateTime {
    const utcDateTime = DateTime.fromISO(isoString, { zone: 'utc' }); // Parse ISO string as UTC
    const danishTime = utcDateTime.setZone('Europe/Copenhagen').setLocale('da-DK'); // Convert to Danish time
    return danishTime;
}

export function IsEarlier(a?: Date, b?: Date): boolean {
    if (!a || !b) return false;
    return a > b;
}