<template>
    <div>
        <div v-if="error">
            <ErrorAlert :message="errorMessage" :retry="retryError"></ErrorAlert>
        </div>
        <BaseFormLayout>
            <template #title>
                <div class="p-2">
                    <BaseHeading heading="InstantApprovalPage.routeDetails.header" help-link="asd" classNames="bold"
                        :level=4 />
                </div>
            </template>

            <template #form>
                <div class="row m-0 p-0">
                    <div class="col-12 col-lg-6" v-for="route in routes" :key="route.Id">
                        <RouteDetailsCard :id="route.Id" :disabled="!previousRouteDetailsValid(route.Id)"
                            :earliest-departure="previousArriavalTime(route.Id)" />
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="button-container col-12 col-lg-6">
                        <BaseFormButton text="Button.previous" class="fa-sharp" icon="fa-arrow-left-long" @click="previous"
                            :isIconAfter=false>
                        </BaseFormButton>

                        <BaseFormButton text="Button.next" icon="fa-arrow-right-long" :disabled="!allRouteDetailsValid"
                            @click="forward">
                        </BaseFormButton>
                    </div>
                </div>
            </template>
        </BaseFormLayout>
    </div>
</template >

<script setup lang="ts">
import BaseFormLayout from '../base/BaseFormLayout.vue';
import RouteDetailsCard from './cards/RouteDetailsCard.vue';
import BaseHeading from '../base/BaseHeading.vue';
import BaseFormButton from '../base/BaseFormButton.vue';
import ErrorAlert from '../ErrorAlert.vue';
import { computed } from 'vue';
import { useDetailsStore } from '@/stores/detailsStore';
import { storeToRefs } from 'pinia';
import { useStepStore } from '@/stores/stepStore';
import { useErrorHandler } from '@/composables/useErrorHandler';

const state = useStepStore();

const detailsStore = useDetailsStore();
const { routes } = storeToRefs(detailsStore);
const { error, errorMessage, retryError } = useErrorHandler(true);

const allRouteDetailsValid = computed(() => {
    return routes.value.every(route =>
        route.DepartureTime !== null
        && route.Direction !== null
        && route.StoppingplaceFrom !== null
        && route.StoppingplaceTo !== null);
});

const previousRouteDetailsValid = computed(() => (key: string) => {
    var index = routes.value.findIndex(x => x.Id === key);
    return routes.value.slice(0, index).every(route =>
        route.DepartureTime !== null
        && route.Direction !== null
        && route.StoppingplaceFrom !== null
        && route.StoppingplaceTo !== null);
});

const previousArriavalTime = computed(() => (key: string) => {
    var index = routes.value.findIndex(x => x.Id === key);
    if (index <= 0) return undefined;

    return routes.value[index - 1].ArrivalTime ?? undefined;
});

function forward() {
    state.submit();
}

function previous() {
    state.back();
}
</script>

<style lang="scss" scoped>
@import '@/styles/components/instantApproval/_instantApproval';
</style>