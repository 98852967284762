import { AlternativeTrips } from '@/models/AlternativeTrip.model';
import type { ApprovalResult } from '@/models/Trip.model';
import alternativeTripsService from '@/services/alternativeTripsService';
import approvalService from '@/services/approvalService';
import { defineStore } from 'pinia';
import { useDetailsStore } from './detailsStore';
import { type StatusState, Status } from '@/enums/status.enum';
import useI18n from '@/composables/useI18n';
import { useStepStore } from './stepStore';
import { error } from 'console';

const { $T } = useI18n();
export const useResultStore = defineStore({
    id: 'result',
    state: () => ({
        result: undefined as ApprovalResult | undefined,
        alternativeTrips: undefined as AlternativeTrips | undefined,
        statusState: { status: Status.LOADED } as StatusState
    }),
    getters: {
    },
    actions: {
        reset() {
            this.result = undefined;
        },
        fetchTripApproval() {
            const store = useDetailsStore();
            const stepStore = useStepStore()
            const trip = store.routesAsTrip();
            this.statusState = { status: Status.LOADING };

            return approvalService.fetchTripApproval(trip)
                .then((result: ApprovalResult | undefined) => {
                    if (!result) {
                        throw new Error("Approval result is undefined");
                    }
                    this.result = result;
                    this.statusState = { status: Status.LOADED };
                    return result;
                })
                .catch(error => {
                    this.statusState = {
                        status: Status.ERROR,
                        message: "Error.TripApproval",
                        retry: () => stepStore.submit()
                    };
                    throw error;
                })
        },
        fetchAlternativeTrips() {
            this.statusState = { status: Status.LOADING };
            const store = useDetailsStore();
            const stepStore = useStepStore();
            const routes = store.routes;

            if (routes && routes.length > 0) {
                const departureTime = new Date(routes[0].DepartureTime ?? "");
                const arrivalTime = new Date(routes[routes.length - 1].ArrivalTime ?? "");
                arrivalTime?.setMinutes(arrivalTime.getMinutes() + 20);
                const input = alternativeTripsService.generateAlternativeTripInput(routes, departureTime, arrivalTime);

                return alternativeTripsService.getAlternativeTrips(input)
                    .then((res) => {
                        this.alternativeTrips = res;
                        this.statusState = { status: Status.LOADED };
                    }).catch(() => {
                        this.statusState = {
                            status: Status.ERROR,
                            message: "Error.AlternativeTrips",
                            retry: () => this.fetchAlternativeTrips()
                        };
                    }).finally(() => {
                        stepStore.step = 5; // Hardcoded to 5 for when retrying after error
                    });
            }
            return Promise.resolve();
        },
    },
});