
import { Status } from '@/enums/status.enum';
import { useResultStore } from '@/stores/resultStore';
import { useTimeTableStore } from '@/stores/timeTablesStore';
import { computed } from 'vue';

export function useErrorHandler(timeTableStore: boolean) {

    const store = timeTableStore ? useTimeTableStore() : useResultStore();

    const error = computed(() => store.statusState.status === Status.ERROR);

    const errorMessage = computed(() => {
        if (store.statusState.status === Status.ERROR && 'message' in store.statusState) {
            return store.statusState.message;
        }
        return '';
    });

    const retryError = () => {
        if ('retry' in store.statusState && typeof store.statusState.retry === 'function') {
            store.statusState.retry();
        }
    };

    return {
        error,
        errorMessage,
        retryError
    };
}
